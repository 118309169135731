"use client";

import globals from "@/context/StyledComponents/globals/globals";
import { pageHead } from "@/lib/heads";
import type { Params } from "@/lib/routes/types";
import type { UiLanguageOnly } from "@/redux/slices/ui/types";
import { useParams, usePathname } from "next/navigation";

import LanguageCN from "@/translation/dictionaries/cn.json";
import LanguageDE from "@/translation/dictionaries/de.json";
import LanguageEN from "@/translation/dictionaries/en.json";
import LanguageES from "@/translation/dictionaries/es.json";
import LanguageFR from "@/translation/dictionaries/fr.json";
import LanguageIT from "@/translation/dictionaries/it.json";
import LanguageJP from "@/translation/dictionaries/jp.json";
import LanguagePT from "@/translation/dictionaries/pt.json";
import LanguageRU from "@/translation/dictionaries/ru.json";

const dictionaries: Record<string, UiLanguageOnly> = {
	es: LanguageES as UiLanguageOnly,
	de: LanguageDE as UiLanguageOnly,
	fr: LanguageFR as UiLanguageOnly,
	it: LanguageIT as UiLanguageOnly,
	en: LanguageEN as UiLanguageOnly,
	pt: LanguagePT as UiLanguageOnly,
	ru: LanguageRU as UiLanguageOnly,
	cn: LanguageCN as UiLanguageOnly,
	jp: LanguageJP as UiLanguageOnly,
};

const Head: React.FC = () => {
	const { lang } = useParams<Params>();
	const pathname = usePathname();

	const translate = (key: keyof UiLanguageOnly) => {
		try {
			return dictionaries[lang][key] || dictionaries.en[key];
		} catch {
			return dictionaries.en[key];
		}
	};

	const { title, description, path, keywords } = pageHead(pathname) as {
		title: keyof UiLanguageOnly;
		description: keyof UiLanguageOnly;
		path: string;
		keywords: string[];
	};
	const showManifest = false;

	return (
		// eslint-disable-next-line @next/next/no-head-element
		<head>
			<title>{`KenaBot - ${translate(title)}`}</title>
			<meta charSet="UTF-8" />
			<meta
				name="viewport"
				content="width=device-width, initial-scale=1.0"
			/>
			<meta name="title" content={`KenaBot - ${translate(title)}`} />
			<meta name="description" content={translate(description)} />
			{showManifest && <link rel="manifest" href="/site.webmanifest" />}

			{/* Open Graph metadata */}
			<meta
				property="og:title"
				content={`KenaBot - ${translate(title)}`}
			/>
			<meta property="og:description" content={translate(description)} />
			<meta property="og:type" content="website" />
			<meta property="og:site_name" content="KenaBot" />
			<meta property="og:url" content={`https://kenabot.xyz${path}`} />
			<meta
				property="og:image"
				content="/assets/images/kenaBot/kena1.png"
			/>

			{/* Twitter Card metadata */}
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:site" content="@kenabotfm" />
			<meta name="twitter:title" content="KenaBot" />
			<meta name="twitter:description" content={translate(description)} />
			<meta
				name="twitter:image"
				content="/assets/images/kenaBot/kena1.png"
			/>
			<meta
				property="twitter:image:src"
				content="/assets/images/kenaBot/kena1.png"
			/>

			{/* Others */}
			<link rel="canonical" href="https://kenabot.xyz" />
			<meta name="canonicalURL" content="https://kenabot.xyz" />
			<meta name="theme-color" content={globals.colors.default} />
			<meta name="robots" content="index, follow" />
			<meta name="keywords" content={keywords.join(", ")} />
			<link
				rel="icon"
				href="/images/favicon-256x256.ico"
				type="image/x-icon"
			/>
			<link rel="icon" href="/images/favicon-32x32.ico" sizes="32x32" />
			<link rel="apple-touch-icon" href="/images/favicon-256x256.ico" />
			<link rel="shortcut icon" href="/images/favicon-256x256.ico" />
			<script
				async
				defer
				src="https://status.kenabot.xyz/tracker.js"
				data-website-id="cm6gn9zeb007dwuvanz83ib2j"
			/>
		</head>
	);
};

export default Head;
